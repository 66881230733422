import {useCallback, useState} from "react";

export type IUseCarouselProps = number | any[];
export interface IUseCarousel {
  onNext: () => void;
  onPrev: () => void;
  current: any;
}

const UseCarousel = ( elementsCount: IUseCarouselProps ): IUseCarousel => {
  const elements =  Array.isArray(elementsCount) ? elementsCount : Array.from(Array(elementsCount).keys())
  const [index, setIndex] = useState<number>( 0 )

  const onNext = useCallback(() => {
    setIndex( index < (elements.length - 1) ? index + 1 : 0)
  },[index, elements]);

  const onPrev = useCallback(() => {
    setIndex( index > 0 ? index - 1 : elements.length -1)
  },[index, elements]);

  return {
    current: elements[index],
    onNext,
    onPrev
  };
}

export default UseCarousel