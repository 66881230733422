import Face1 from './images/face1.png';
import Face2 from './images/face2.png';
import Face3 from './images/face3.png';
import Face4 from './images/face4.png';
import Face5 from './images/face5.png';
import Face6 from './images/face6.png';
import Face7 from './images/face7.png';
import Face8 from './images/face8.png';
// import Face9 from './images/face9.png';
// import Face10 from './images/face10.png';
// @ts-ignore
export const FACES: string[] = [
  Face1,
  Face2,
  Face3,
  Face4,
  Face5,
  Face6,
  Face7,
  Face8,
  // Face9,
  // Face10
]