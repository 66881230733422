import styled from 'styled-components';

export const ScreenCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const LogoPosition = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`

export const GridTreeVertical = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    flex-direction: column;
    & > div {
        display: flex;
        height: 33.3333%;
        //outline: 1px solid red;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
`

export const GapButtons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`