/**
 * Interfaces
 */
export interface IHeart {
  draw():void;
  update( heartsArray: IHeart[] ):void;
  // updateCtxSize:( width: number, height: number) => void;
  updateXY(x:number, y: number):void;
}

export class Heart implements IHeart {
  private x: number;
  private y: number;
  private scale: number;
  private readonly color: string;
  private readonly targetScale: number;
  private readonly ctx: CanvasRenderingContext2D | null;
  constructor(x: number, y: number, scale: number, color: string, targetScale: number, ctx: CanvasRenderingContext2D) {
    this.x = x;
    this.y = y;
    this.scale = scale;
    this.color = color;
    this.targetScale = targetScale;
    this.ctx = ctx;
  }

  draw() {
    if (!this.ctx) return;
    this.ctx.save();

    this.ctx.translate(this.x, this.y);
    this.ctx.scale(this.scale, this.scale);
    this.ctx.translate(0,- 50);
    this.ctx.fillStyle = this.color;
    this.ctx.beginPath();
    this.ctx.moveTo(0, 12);
    this.ctx.bezierCurveTo(50, -30, 110, 50, 0, 120);
    this.ctx.bezierCurveTo(-110, 50, -50, -30, 0, 12);
    this.ctx.closePath();
    this.ctx.fill();

    this.ctx.restore();
  }

  update( heartsArray: IHeart[] ) {
    if (this.scale < this.targetScale) {
      this.scale += 0.02;
    } else {
      this.scale = 0;
      heartsArray.push( heartsArray.shift() as IHeart);
    }
    this.draw()
  }
  updateXY( x: number,y: number ) {
    this.x = x;
    this.y = y;
    this.draw();
  }
}