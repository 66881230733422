import styled from 'styled-components';
import {FC, ReactNode, memo} from "react";

interface ITextStyled {
    fontSize: number;
}
interface IFunnyText {
    children: ReactNode;
    fontSize?: number
}

const TextStyled = styled.div<ITextStyled>`
    display: inline-block;
    vertical-align: middle;
    outline: none;
    text-align: center;
    line-height: 1;
    font-family: 'Amatic SC', sans-serif;
    font-size: ${p => p.fontSize}px;
    font-weight: 700;
    color: white;
    text-shadow: #333 2px 2px 8px;
    -webkit-user-select: none;
`;

const FunnyText:FC<IFunnyText> = ({children, fontSize = 50}) => (
    <TextStyled fontSize={fontSize}>
        {children}
    </TextStyled>
);
export default memo(FunnyText)