import styled from 'styled-components';
export default styled.div`
    text-align: center;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    max-width: 1800px;
    max-height: 1400px;
    display: flex;
    justify-self: center;
    align-self: center;
    position: relative;
    border: 5px solid #6C3F18;
    border-radius: 10px;
`;