import styled from 'styled-components';
import { FC, useRef, useEffect } from "react";
import { Heart, IHeart } from './hearts'

/**
 * Consts
 */
const NUMBER_OF_HEARTS:number = 12;
const COLORS_ARRAY = [
    "#D52429",
    "#F1602C",
    "#EC8922",
    "#915018",
    "#6C3F18"
];

/**
 * Styled
 */
const CanvasStyled = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    transform: rotate3d(0,0,0,0);
    z-index: 0;
`;

const BgHearts: FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const resizeObserver = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
            if (!ctx) return;
            ctx.globalCompositeOperation='destination-over';

            canvas.width = window.innerWidth || 0;
            canvas.height = window.innerHeight || 0;

            const hearts: IHeart[]= [];

            for (let n = NUMBER_OF_HEARTS; n >= 1; n--) {
                const scale = n;
                const color = COLORS_ARRAY[(NUMBER_OF_HEARTS-n) % COLORS_ARRAY.length];
                hearts.push(new Heart(
                  canvas.width / 2,
                  canvas.height / 2,
                  scale * 2,
                  color,
                  NUMBER_OF_HEARTS * 2,
                  ctx
                ));
            }

            const animate = () => {
                ctx?.clearRect(0, 0, canvas?.width as number, canvas?.height as number);
                hearts.forEach((heart: IHeart) => heart.update( hearts ));
                requestAnimationFrame(animate);
            }
            animate();

            resizeObserver.current = new ResizeObserver((entries) => {
                requestAnimationFrame(() => {
                    const {width, height} = entries[0].contentRect

                    canvas.width = width;
                    canvas.height = height;

                    hearts.forEach((heart: IHeart) => heart.updateXY( width /2, height /2));
                })
            })

            resizeObserver.current?.observe(canvas)
        }
        return () => resizeObserver.current?.disconnect()
    }, []);

    return <CanvasStyled ref={canvasRef}></CanvasStyled>;
};

export default BgHearts;