import styled from 'styled-components';

const Button = styled.button`
    display: inline-flex;
    background-color: #EC8922;
    border: 0;
    border-radius: 56px;
    color: #fff;
    cursor: pointer;
    font-family: 'Amatic SC Bold', sans-serif;
    font-size: 40px;
    font-weight: 700;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .150s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    overflow: hidden;
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset,
                rgba(0, 0, 0, .1) 0 3px 5px,
                rgba(0, 0, 0, .1) 0 10px 13px;

    &:before {
        background-color: initial;
        background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
        border-radius: 125px;
        content: "";
        height: 50%;
        left: 4%;
        opacity: .5;
        position: absolute;
        top: 0;
        transition: all .3s;
        width: 92%;
    }

    &:hover {
        z-index: 5;
        transform: scale(1.2);
    }
    &:active {
        transform: scale(1.15);
        transition: all .050s;
        box-shadow: rgba(255, 255, 255, .2) 0 1px 1px inset,
                    rgba(0, 0, 0, .1) 0 1px 1px,
                    rgba(0, 0, 0, .1) 0 1px 3px;
    }

    @media (min-width: 768px) {
        & {
            padding: 16px 48px;
        }
    }
`
export default Button;