import React from 'react';
import { Reset } from 'styled-reset'
import AppUI from './ui/App'
import Global from './ui/global'
// import ImagesPreload from './system/ImagesPreload';
import {SVGBlockForShakeText} from "./ui/Animations";
import MainPage from './pages/MainPage';
const App = () =>{
    return (
        <>
          <Reset />
          <Global />
          <SVGBlockForShakeText />
          <AppUI>
              <MainPage />
          </AppUI>
        </>
    );
}

export default App;
