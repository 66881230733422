import { createGlobalStyle } from "styled-components"
import AmaticSC_Regular from "./../fonts/AmaticSC-Regular.ttf";
import AmaticSC_Bold from "./../fonts/AmaticSC-Bold.ttf";
export default createGlobalStyle`
  html,
  body {
      width: 100%;
      height: 100%;
  }
  body {
      overflow:hidden;
      background-color: #282c34;
      font-family: 'Amatic SC', sans-serif;
      display: flex;
  }
  #root {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-content: center;
  }
  
  /* regular */
  @font-face {
      font-family: "Amatic SC";
      font-style: normal;
      font-weight: 400;
      src: url('${AmaticSC_Regular}') format('truetype');
      //unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
  }
  /* bold */
  @font-face {
      font-family: 'Amatic SC Bold';
      font-style: normal;
      font-weight: 700;
      src: local("Amatic SC Bold"),
      url('${AmaticSC_Bold}')  format('truetype');
      //unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }
  @keyframes squiggly-anim {
      0% {
          filter: url('#squiggly-0')
      }
      25% {
          filter: url('#squiggly-1')
      }
      50% {
          filter: url('#squiggly-2')
      }
      75% {
          filter: url('#squiggly-3')
      }
      100% {
          filter: url('#squiggly-4')
      }
  }
`