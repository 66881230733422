import {FC, ReactNode} from "react";
import styled from 'styled-components';

export interface IAnimationIn {
  children: ReactNode
  delay?: number;
}

const AnimationInRotatedStyled = styled.div<{$delay: number}>`
    transform: scale(0) rotate(0deg);
    @keyframes animationInRotated {
        0% {
            transform: scale(0) rotate(0deg);
        }
        80% {
            transform: scale(1.1) rotate(360deg);;
        }
        100% {
            transform: scale(1) rotate(360deg);
        }
    }
    animation: animationInRotated 0.44s forwards;
    animation-delay: ${p => p.$delay}ms;
`

export const AnimationInRotated:FC<IAnimationIn> = ({children, delay = 0}) => (
  <AnimationInRotatedStyled $delay={delay}>
    {children}
  </AnimationInRotatedStyled>
);

export const AnimationSquiggly = styled.div`
  animation: squiggly-anim 0.44s linear infinite;
`;

export const SVGBlockForShakeText = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{display: 'none'}}>
    <defs>
      <filter id="squiggly-0">
        <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="0"/>
        <feDisplacementMap id="displacement" in="SourceGraphic" in2="noise" scale="6" />
      </filter>
      <filter id="squiggly-1">
        <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="1"/>
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
      </filter>
      <filter id="squiggly-2">
        <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="2"/>
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
      </filter>
      <filter id="squiggly-3">
        <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="3"/>
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
      </filter>
      <filter id="squiggly-4">
        <feTurbulence id="turbulence" baseFrequency="0.02" numOctaves="3" result="noise" seed="4"/>
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
      </filter>
    </defs>
  </svg>
)