import styled, {css} from 'styled-components';
export default styled.div<{$sleep: boolean}>`
    @keyframes brightness {
        0% {
            filter: brightness(1);
        }
        100% {
            filter: brightness(0.5);
        }
    }
    width: 100%;
    height: 100%;
    padding: inherit;
    margin-top: inherit;
    border: none;
    position: relative;
    ${p => p.$sleep && css`
        animation: brightness 0.44s ease-in-out forwards;
        animation-delay: 500ms;
    `}
`;