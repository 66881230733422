import {FC, memo} from 'react';
import styled, {css} from "styled-components";
import { GiNightSleep } from "react-icons/gi";

const FaceRoundedStyled = styled.div<{$sleep: boolean}>`
    @keyframes animationShake {
        0% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(5deg);
        }
    }
    overflow: hidden;
    display: flex;
    justify-content: center;
    & {
        width: 300px;
        height: 300px;     
    }
    & > img {
        max-height: 300px;
        max-width: 300px;
    }
    background: #EC8922;
    border-radius: 100%;
    border: 7px solid #fff;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.4);
    cursor: pointer;
    transition: all 100ms ease-in-out;
    animation: animationShake 0.44s ease-in-out infinite alternate;
    &:hover {
        & > img {
            transform: scale(1.1);
            ${p => p.$sleep && css`
                transform: scale(1.1) translateY(40%);
            `}
        }
    }
    & > img {
        position: relative;
        background-size: contain;
        transition: all 500ms ease-in-out;
        transform: translateY(0);
        ${p => p.$sleep && css`
            //animation: none;
            //transition: all 500ms ease-in-out;
            transform: translateY(100%);
        `}
    }
    &:active {
        & > img {
            animation: none;
            transform: scale(1.5);
        }
    }
`;
const SleepStyled = styled.div<{$sleep: boolean}>`
    transform: scale(0);
    transition: all 500ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${p => p.$sleep && css`
        transform: scale(1);
    `}
`

const FaceRounded:FC<{sleep: boolean, url: string}> = ({sleep = false, url = ''}) => {
  return (
    <FaceRoundedStyled $sleep={sleep}>
      <SleepStyled $sleep={sleep}>
        <GiNightSleep color="white" size={100}/>
      </SleepStyled>
      <img src={url} alt="" draggable="false"/>
    </FaceRoundedStyled>
  )
}

export default memo(FaceRounded)