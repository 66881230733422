import React, {memo} from "react";
import {GapButtons, GridTreeVertical, LogoPosition} from "../ui/elements";
import {AnimationInRotated, AnimationSquiggly} from "../ui/Animations";
import FunnyText from "../ui/FunnyText";
import Button from "../ui/Button";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import FaceRounded from "../ui/FaceRounded";
import Sleep from "../ui/Sleep";

import { useToggle } from "@uidotdev/usehooks";
import useCarousel from '../hooks/hooks.useCarousel';
import BgHearts from "../ui/BgHearts";
import { FACES } from '../images';

const MainPage = () => {
  const [sleep, toggleSleep] = useToggle(false );
  const {current, onPrev, onNext} = useCarousel( FACES.length );

  return (
    <Sleep $sleep={sleep}>
      <BgHearts />
      <GridTreeVertical>
        <LogoPosition>
          <AnimationSquiggly>
            <FunnyText fontSize={150}>Svidetel Windows</FunnyText>
          </AnimationSquiggly>
        </LogoPosition>
        <div>
          <GapButtons>
            <div>
              {!sleep && <Button onClick={onPrev}><FaArrowLeft /></Button>}
            </div>
            <div>
              <AnimationInRotated delay={300}>
                <FaceRounded sleep={sleep} url={FACES[current]} />
              </AnimationInRotated>
            </div>
            <div>
              {!sleep && <Button onClick={onNext}><FaArrowRight /></Button>}
            </div>
          </GapButtons>
        </div>
        <GapButtons>
          <Button onClick={()=>toggleSleep()}>{sleep ? 'Проснуться' : 'Спать'}</Button>
          {!sleep && <Button>Поехали</Button>}
        </GapButtons>
      </GridTreeVertical>
    </Sleep>
  );
}
export default memo(MainPage)